<template lang="pug">
    modal.add-homepage-group-modal
        template(v-slot:body)
            div.step-1(v-if="currentStep === 1")
                .head
                    p.title-2 {{$t('homepage.group.setup')}}
                    div.input-container.input-form.rounded.with-icon
                        img.icon(src="./svg/search-icon.svg")
                        input(v-model="search" :placeholder="$t('group')")
                .group-list
                    .group-row(v-for="group in allGroups" @click="selectedGroup = group; currentStep = 2" v-if="group.label.toLowerCase().indexOf(search.toLowerCase()) != -1 && !group.extraproperties.on_homepage" :class="{'selected': group.extraproperties && group.extraproperties.on_homepage}")
                        div.pastille(v-if="group.extraproperties" :style="'background:' + group.extraproperties.color")
                        p.title-4 {{group.label}}
            div.step-2(v-if="currentStep === 2")
                update-group-image-form(:group="selectedGroup" :withTitle="false" :withBack="true" @back="currentStep--")
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
    name: "AddHomePageGroup",
    components: {
        Modal: () => import('skillbase-component/src/components/common/Modal.vue'),
        UpdateGroupImageForm: () => import('skillbase-component/src/components/group/components/UpdateGroupImageForm.vue')
    },

    data() {
        return {
            search: "",
            currentStep: 1,
            selectedGroup: false
        }
    },
    computed: {
        allGroups() {
            let toReturn = this.$store.getters['group/groupList/allGroupList'];
            let groups = [];
            if(toReturn) {
                groups = toReturn.sort(function(a, b){
                    return a.label >= b.label ? 1 : -1;
                })
            }
            return groups
        }
    }
})
</script>

<style lang="scss">
.add-homepage-group-modal {
    overflow-y: hidden;

    .popup {
        padding: 0 !important;
        max-width: 600px;
        width: 600px;
        height: 780px;
    }

    .head {
        background: var(--primary-color);
        padding: 40px 40px 20px 40px;

        p {
            color: white;
        }

        .input-container {
            height: 60px;
            position: relative;
            display: flex;
            align-items: center;
            margin-top: 20px;

            .icon {
                height: 18px;
                width: 18px;
                top: 21px;
                filter: var(--svg-filter-white-color);
            }

            input {
                background: var(--lighter-input-primary-color);
                box-shadow: none;
                color: white;
                padding: 20px 40px;
                &::placeholder {
                    color: white;
                }
            }
        }
    }

    .confirm-btn {
        position: absolute;
        right: calc(50vw - 25px);
        bottom: calc(50vh - 300px);
    }

    .group-list {
        padding: 20px 35px;
        .group-row {
            display: flex;
            align-items: center;
            padding: 15px 20px;
            cursor: pointer;
            border-radius: 5px;
            .pastille {
                height: 14px;
                width: 14px;
                border-radius: 50px;
                margin-right: 20px;
            }
            &:hover {
                background: var(--lighter-gray-color);
            }
        }
    }

    .step-2 {
        .group-info-block {
            background: var(--lighter-gray-color);
            display: flex;
            align-items: center;
            height: 20px;
            padding: 20px 40px;

            .pastille {
                height: 14px;
                width: 14px;
                border-radius: 50px;
                margin-right: 20px;
            }
        }
    }
}

</style>